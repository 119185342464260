<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bütçe Diğer Gelir</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="12">
            <rs-select
              label="Dağıtım Anahtarı"
              v-model="formData.distribution_type_id"
              :rules="[rules.required]"
              :items="budgetDistributionTypeList"
              :filled="isLoading"
              :readonly="isLoading"
            >
            </rs-select>
          </v-col>
          <v-col sm="12">
            <rs-select
              label="Diğer Gelir Türü"
              v-model="formData.expense_type_id"
              :rules="[rules.required]"
              :items="customExpenseTypeList"
              :filled="isLoading"
              :readonly="isLoading"
            >
            </rs-select>
          </v-col>
          <v-col sm="12">
            <rs-money-field
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.01),
                rules.max(formData.amount, 9999999.99),
              ]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col sm="12">
            <rs-text-field
              label="Açıklama"
              :rules="[rules.maxLength(formData.description, 100)]"
              v-model="formData.description"
              :disabled="isLoading"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          hide-submit
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";
import { mapGetters } from "vuex";

export default {
  mixins: [hasForm],
  data() {
    return {
      id: null,
      formData: {
        budget_id: null,
        expense_type_id: null,
        distribution_type_id: null,
        amount: null,
        description: null,
      },
    };
  },
  computed: {
    ...mapGetters(["positiveExpenseTypeList", "budgetDistributionTypeList"]),
    customExpenseTypeList() {
      if (!this.positiveExpenseTypeList) {
        return [];
      }

      return this.positiveExpenseTypeList;
    },
  },
  methods: {
    show(id, budgetId) {
      if (id) {
        this.id = id;
        this.load();
      } else {
        this.formData = {
          budget_id: null,
          expense_type_id: null,
          distribution_type_id: null,
          amount: null,
          description: null,
        };
      }
      if (budgetId) {
        this.formData.budget_id = budgetId;
      } else {
        return false;
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    load() {
      if (this.isLoading || !this.id) {
        return;
      }

      this.isLoading = true;

      this.$api
        .get(`budget-other-incomes/${this.id}`)
        .then((response) => {
          this.formData = Object.assign({}, response.data.data);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      if (this.id) {
        this.$api
          .put(`budget-other-incomes/${this.id}`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`budget-other-incomes`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
