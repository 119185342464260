<template>
  <div>
    <v-data-table
      v-if="clusterId"
      v-bind="dataTableAttrs"
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
    >
      <template v-slot:top>
        <rs-table-title-bar
          title=""
          icon=""
          hide-edit
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          :search.sync="search.query"
          v-if="!hideTitleBar && $refs.headSearch"
        >
        </rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.edit="{ item }">
        <router-link
          class="btn btn-icon btn-sm btn-clean"
          :to="{
            name: 'definitions.houses.edit',
            params: { id: item.id },
          }"
        >
          <i class="menu-icon mdi mdi-pencil"></i>
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.door_number="{ item, value }">
        <router-link
          :to="{
            name: 'definitions.houses.show',
            params: { id: item.id },
          }"
        >
          {{ value }}
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.renter="{ item }">
        <router-link
          v-for="(item, i) in getRenterNames(item)"
          :key="i"
          :to="{
            name: 'definitions.residents.edit',
            params: { id: item.resident_id },
          }"
        >
          {{ item.resident.name }}

          <v-tooltip bottom v-if="item.is_staying">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" dark v-bind="attrs" v-on="on">
                mdi-account-circle
              </v-icon>
            </template>
            Burada oturuyor.
          </v-tooltip>
        </router-link>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.owner="{ item }">
        <router-link
          v-for="(item, i) in getOwnerNames(item)"
          :key="i"
          :to="{
            name: 'definitions.residents.edit',
            params: { id: item.resident_id },
          }"
        >
          {{ item.resident.name }}

          <v-tooltip bottom v-if="item.is_staying">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" dark v-bind="attrs" v-on="on">
                mdi-account-circle
              </v-icon>
            </template>
            Burada oturuyor.
          </v-tooltip>
        </router-link>
      </template>
      <!-- eslint-disable -->

      <template v-slot:item.total_assessment="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>

      <template v-slot:item.total_payed="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>

      <template v-slot:item.balance="{ value }">
        <rs-table-cell-balance colored :value="value" />
      </template>

      <template v-slot:item.renters_balance="{ value }">
        <rs-table-cell-balance colored :value="value" />
      </template>

      <template v-slot:item.owners_balance="{ value }">
        <rs-table-cell-balance colored :value="value" />
      </template>

      <template v-slot:item.has_periodical_assessments="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <template v-slot:item.has_multiple_owners="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <template v-slot:item.is_active="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <template v-slot:item.has_future_owner="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <template v-slot:item.nodwelt="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <!-- eslint-enable -->
    </v-data-table>

    <p v-else>Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  mixins: [hasDataTable],
  computed: {
    ...mapGetters(["blocks", "clusterId", "houseTypes"]),
  },
  data() {
    return {
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      options: {
        sortBy: ["house_type_name"],
      },
      headers: [
        {
          text: "B.B. No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.block"),
          value: "block_name",
          searchable: "multiselect",
          options: () => this.blocks,
          itemText: "name",
          width: "75px",
          align: "center",
        },
        {
          text: "B.B. Tipi",
          value: "house_type_name",
          searchable: "multiselect",
          options: () => this.houseTypes,
          itemText: "name",
          width: "75px",
          align: "center",
        },
        {
          text: "Kat",
          value: "floor",
          searchable: "text",
        },
        {
          text: "Tahakkuk Eden",
          value: "total_assessment",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödenen Tutar",
          value: "total_payed",
          searchable: "number",
          align: "end",
        },
        {
          text: "BB Bakiye",
          value: "balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kiracı",
          value: "renter",
          searchable: "text",
          width: "200px",
        },
        {
          text: "Kiracı Bakiye",
          value: "renters_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ev Sahibi",
          value: "owner",
          searchable: "text",
          width: "200px",
        },
        {
          text: "Ev Sahibi Bakiye",
          value: "owners_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Periyodik Borçlandırma",
          value: "has_periodical_assessments",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "Hissedarlı",
          value: "has_multiple_owners",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "Durum",
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
        {
          text: "Sözleşmeli",
          value: "has_future_owner",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "Oturan Yok",
          value: "nodwelt",
          searchable: "yesNo",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );
      this.$api
        .query(`clusters/${this.clusterId}/houses`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getRenterNames(item) {
      const renterNames = [];

      for (const houseUser of item.house_users) {
        if (houseUser.house_user_type_id === 2) {
          renterNames.push(houseUser);
        }
      }

      return renterNames;
    },
    getOwnerNames(item) {
      const renterNames = [];

      for (const houseUser of item.house_users) {
        if (houseUser.house_user_type_id === 1) {
          renterNames.push(houseUser);
        }
      }

      return renterNames;
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }, 250),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
    extraParams: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    selectedItems(newValue) {
      this.$emit("update", newValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
