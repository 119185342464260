var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.clusterId)?_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.hideTitleBar && _vm.$refs.headSearch)?_c('rs-table-title-bar',{attrs:{"title":"","icon":"","hide-edit":"","search":_vm.search.query},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}}):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
          name: 'definitions.houses.edit',
          params: { id: item.id },
        }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.door_number",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
          name: 'definitions.houses.show',
          params: { id: item.id },
        }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.renter",fn:function({ item }){return _vm._l((_vm.getRenterNames(item)),function(item,i){return _c('router-link',{key:i,attrs:{"to":{
          name: 'definitions.residents.edit',
          params: { id: item.resident_id },
        }}},[_vm._v(" "+_vm._s(item.resident.name)+" "),(item.is_staying)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-circle ")])]}}],null,true)},[_vm._v(" Burada oturuyor. ")]):_vm._e()],1)})}},{key:"item.owner",fn:function({ item }){return _vm._l((_vm.getOwnerNames(item)),function(item,i){return _c('router-link',{key:i,attrs:{"to":{
          name: 'definitions.residents.edit',
          params: { id: item.resident_id },
        }}},[_vm._v(" "+_vm._s(item.resident.name)+" "),(item.is_staying)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-circle ")])]}}],null,true)},[_vm._v(" Burada oturuyor. ")]):_vm._e()],1)})}},{key:"item.total_assessment",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.total_payed",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.balance",fn:function({ value }){return [_c('rs-table-cell-balance',{attrs:{"colored":"","value":value}})]}},{key:"item.renters_balance",fn:function({ value }){return [_c('rs-table-cell-balance',{attrs:{"colored":"","value":value}})]}},{key:"item.owners_balance",fn:function({ value }){return [_c('rs-table-cell-balance',{attrs:{"colored":"","value":value}})]}},{key:"item.has_periodical_assessments",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.has_multiple_owners",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.is_active",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.has_future_owner",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.nodwelt",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}}],null,false,4091551243),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false)):_c('p',[_vm._v("Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }