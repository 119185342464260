<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Detay Gider</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="12">
            <rs-select
              :items="childExpenseTypeList.filter((item) => item.is_active)"
              label="Gider Tipi"
              v-model="formData.expense_type_id"
              :readonly="isLoading"
              :filled="isLoading"
              :rules="[rules.required]"
              ref="childExpenseType"
            >
              <template slot="append-outer" v-if="can('edit-expense-type')">
                <v-btn
                  small
                  icon
                  outlined
                  color="pink"
                  @click="handleAddExpenseTypeClick"
                  title="Yeni gider tipi ekle"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
            </rs-select>
          </v-col>
          <v-col sm="12">
            <rs-money-field
              label="Tutar"
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.01),
                rules.max(formData.amount, 9999999.99),
              ]"
              :disabled="isLoading"
            />
          </v-col>
          <!-- <v-col sm="12">
            <rs-text-field
              type="number"
              label="Sapma Payı"
              :rules="[rules.required]"
              v-model="formData.error_margin"
              :readonly="isLoading"
              :filled="isLoading"
              prefix="%"
            />
          </v-col> -->
          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              rows="5"
              no-resize
              :disabled="isLoading"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          @submit="handleFormSubmit"
          hide-save-close
        />
      </v-form>
      <ChildExpenseTypeForm
        ref="childExpenseTypeForm"
        @dismissed="$refs.childExpenseTypeForm.hide()"
        @saved="handleChildExpenseTypeFormSaved"
        parent-id-disabled
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";
import { mapGetters } from "vuex";
import ClusterService from "@/core/services/cluster.service";
import ChildExpenseTypeForm from "@/view/pages/expense/forms/ChildExpenseTypeForm";

export default {
  mixins: [hasForm, hasPermissions],
  components: {
    ChildExpenseTypeForm,
  },
  data() {
    return {
      id: null,
      budgetItemId: null,
      formData: {
        expense_type_id: null,
        amount: null,
        description: null,
      },
      budgetItem: {},
    };
  },
  computed: {
    ...mapGetters(["clusterId"]),
    childExpenseTypeList() {
      if (!this.budgetItem.expense_type_id) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(
        this.budgetItem.expense_type_id
      );
    },
  },
  methods: {
    show(id, budgetItemId) {
      if (id) {
        this.id = id;
        this.load();
      } else {
        this.formData = {
          expense_type_id: null,
          amount: null,
          description: null,
        };
      }
      if (budgetItemId) {
        this.budgetItemId = budgetItemId;
        this.loadBudgetItem();
      } else {
        return false;
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    load() {
      if (this.isLoading || !this.id) {
        return;
      }

      this.isLoading = true;

      this.$api
        .get(`budget-subitems/${this.id}`)
        .then((response) => {
          this.formData = Object.assign({}, response.data.data);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      if (this.id) {
        this.$api
          .put(`budget-subitems/${this.id}`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        formData.budget_item_id = this.budgetItemId;
        this.$api
          .post(`budget-subitems`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    loadBudgetItem() {
      this.budgetItem = {};

      return this.$api
        .get(`budget-items/${this.budgetItemId}`)
        .then((response) => {
          this.budgetItem = Object.assign({}, response.data.data);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleCancelClick() {
      this.hide();
    },
    handleAddExpenseTypeClick() {
      this.$refs.childExpenseTypeForm.show(
        null,
        this.budgetItem.expense_type_id
      );
    },
    handleChildExpenseTypeFormSaved(child) {
      this.$refs.childExpenseType.pushOption(child);
      this.formData.expense_type_id = child.id;
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
<style lang="scss">
.custom-tabs.theme--light.v-tabs > .v-tabs-bar,
.custom-item-tabs.theme--light.v-tabs-items {
  background-color: transparent !important;
  padding: 0.5em;
}
</style>
