<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bütçe Kodu Muafiyet BB</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="12">
            <rs-text-field
              type="number"
              min="1"
              step="1"
              max="100"
              label="Muafiyet Oranı"
              v-model="formData.exemption_ratio"
              :rules="[rules.required]"
              :readonly="isLoading"
              :filled="isLoading"
              prefix="%"
            />
          </v-col>
          <v-col sm="12">
            <BBList @update="handleSelectionUpdate" />
          </v-col>
        </v-row>
        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          hide-submit
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";
import BBList from "../../../exempt/BBList";

export default {
  mixins: [hasForm],
  data() {
    return {
      formData: {},
      selectedIds: [],
    };
  },
  components: {
    BBList,
  },
  methods: {
    show(budgetItemId) {
      this.formData = {};
      this.formData.exemption_ratio = 100;
      if (budgetItemId) {
        this.formData.budget_item_id = budgetItemId;
      } else {
        return false;
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      formData.house_id = this.selectedIds;

      this.$api
        .post(`budget-item-exempts`, formData)
        .then(() => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleSelectionUpdate(newItems) {
      this.selectedIds = newItems.map((v) => v.id);
    },
  },
};
</script>
