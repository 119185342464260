<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bütçe Kalemi</template>
    <template slot="body">
      <v-tabs v-model="detailTab" class="custom-tabs">
        <v-tab to="#detail-infos" replace>Bütçe Kalem Bilgileri</v-tab>
        <v-tab
          to="#detail-outcomes"
          :disabled="!formData.id || !formData.has_details"
          replace
          >Detay Giderler</v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="detailTab" class="custom-item-tabs">
        <v-tab-item value="detail-infos">
          <v-form @submit.prevent="handleFormSubmit" ref="form">
            <v-row cols="12" dense>
              <v-col sm="12">
                <rs-select-expense-type
                  v-model="formData.expense_type_id"
                  :label="$t('labels.expense_type')"
                  :rules="[rules.required]"
                  :readonly="isLoading"
                  :filled="isLoading"
                />
              </v-col>
              <v-col sm="12">
                <rs-select
                  :items="amountTypeList"
                  label="Tutar Tipi"
                  v-model="formData.amount_type_id"
                  :readonly="isLoading"
                  :filled="isLoading"
                  :rules="[rules.required]"
                  hint="Aylık seçildiğinde girilen tutarın her ay tekrar ettiği varsayılır. Toplam girerseniz tüm bütçe için tek miktar girebilirsiniz."
                />
              </v-col>
              <v-col sm="12" v-if="!has_details">
                <rs-money-field
                  label="Tip Tutar"
                  v-model="formData.amount"
                  :rules="[
                    rules.required,
                    rules.min(formData.amount, 0.01),
                    rules.max(formData.amount, 9999999.99),
                  ]"
                  :disabled="isLoading"
                  :hint="
                    formData.amount_type_id === 1
                      ? 'Aylık gider tutarı'
                      : 'Toplam gider tutarı'
                  "
                />
              </v-col>
              <v-col sm="12">
                <rs-text-field
                  type="number"
                  label="Sapma Payı"
                  :rules="[rules.required]"
                  v-model="formData.error_margin"
                  :readonly="isLoading"
                  :filled="isLoading"
                  prefix="%"
                  hint="Bütçeyi aktifleştirdiğinizde gerçekleşen tutarla planlanan tutar arasındaki en yüksek farkı belirtir."
                />
              </v-col>
              <v-col sm="12">
                <rs-select
                  label="Dağıtım Anahtarı"
                  v-model="formData.distribution_type_id"
                  :rules="[rules.required]"
                  :items="budgetDistributionTypeList"
                  :filled="isLoading"
                  :readonly="isLoading"
                  hint="Bütçe kalemi bağımsız bölümlerin seçeceğiniz özelliğine göre dağıtılır. Bağımsız bölümlerin tamamında bu özelliğin girildiğinden emin olun."
                >
                </rs-select>
              </v-col>
              <v-col sm="12">
                <v-switch
                  v-model="has_details"
                  label="Detay Gir"
                  hide-details="auto"
                  hint="Aktif duruma getirirseniz bütçe kaleminin detaylarını gider tipi bazında girebilirsiniz."
                  persistent-hint
                ></v-switch>
              </v-col>
            </v-row>

            <rs-form-buttons
              @cancel="handleCancelClick"
              :is-loading="isLoading"
              @submit="handleFormSubmit"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="detail-outcomes">
          <ExpensesList :budget-item-id="formData.id" @saved="expenseChange" />
        </v-tab-item>
      </v-tabs-items>
      <div v-if="formData.id">
        <v-divider class="my-5" />
        <v-row dense v-if="id && totals.month_count">
          <v-col cols="12" sm="4" lg="3">
            <rs-text-field
              label="Dönem Sayısı"
              filled
              readonly
              :value="
                totals.month_count + ' ay / ' + totals.days_count + ' gün'
              "
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Planlanan Gider"
              :value="totals.total_amount"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Aylık Gider Tutar"
              :value="totals.monthly_amount"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Gerçekleşen Tutar"
              :value="totals.actual_amount"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Gerçekleşen Aylık Tutar"
              :value="totals.monthly_actual_amount"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Yıl Sonu Tahmini Tutar"
              :value="totals.expected_amount"
            />
          </v-col>
        </v-row>
        <v-divider class="my-5" />
        <v-tabs v-model="selectedFooterTab" class="custom-tabs">
          <v-tab to="#budget-months" replace>Dönemsel Tutarlar</v-tab>
          <v-tab to="#detail-exempts" replace>Muaf Bağımsız Bölümler</v-tab>
          <v-tab to="#invoices" replace>Gerçekleşen Faturalar</v-tab>
          <v-tab to="#item-analysis" replace
            >Aylık Plan-Gerçekleşen Analiz</v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="selectedFooterTab" class="custom-item-tabs">
          <v-tab-item value="budget-months">
            <MonthsList :budget-item-id="formData.id" ref="monthList" />
          </v-tab-item>
          <v-tab-item value="detail-exempts">
            <DetailExemptList
              :budget-item-id="formData.id"
              ref="detailExemptList"
            />
          </v-tab-item>
          <v-tab-item value="invoices">
            <InvoiceItemActiveList
              :budget-item-id="formData.id"
              ref="invoiceItemActiveList"
            />
          </v-tab-item>
          <v-tab-item value="item-analysis">
            <apexchart
              v-if="analysisData.length"
              type="line"
              :series="analysisData"
              :options="analysisOptions"
            />
            <!-- <InvoiceItemActiveList
              :budget-item-id="formData.id"
              ref="invoiceItemActiveList"
            /> -->
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";
import hasForm from "@/view/mixins/hasForm";
import ExpensesList from "./components/expenses/ExpensesList.vue";
import MonthsList from "./components/months/MonthsList.vue";
import DetailExemptList from "./components/detail-exempt/DetailExemptList.vue";
import InvoiceItemActiveList from "../../invoices/InvoiceItemActiveList.vue";

export default {
  mixins: [hasForm],
  data() {
    return {
      id: null,
      budgetId: null,
      detailTab: "detail-infos",
      selectedFooterTab: "budget-months",
      formData: {},
      analysisOptions: {},
      analysisData: [],
      totals: {},
      has_details: null,
    };
  },
  computed: {
    ...mapGetters(["budgetDistributionTypeList", "amountTypeList"]),
  },
  components: {
    InvoiceItemActiveList,
    ExpensesList,
    MonthsList,
    DetailExemptList,
  },
  methods: {
    show(id, budgetId) {
      if (id) {
        this.id = id;
        this.load();
        this.loadAnalysis();
      } else {
        this.formData = {};
        this.formData.error_margin = 0;
        this.formData.amount_type_id = 1;
        this.has_details = null;
      }
      if (budgetId) {
        this.budgetId = budgetId;
      } else {
        return false;
      }

      this.analysisData = [];
      this.analysisOptions = {};

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    loadAnalysis() {
      this.$api
        .get(`budget-stats/budget-items/${this.id}/monthly`)
        .then((response) => {
          this.analysisOptions = {
            stroke: {
              curve: "smooth",
              dashArray: [5, 0],
            },
            xaxis: {
              categories: response.data.data.map((x) => x.month + "/" + x.year),
            },
            yaxis: {
              labels: {
                formatter: (v) => this.numberToLocaleFormat(v) + "₺",
              },
            },
            plotOptions: {
              line: {
                dataLabels: {
                  position: "top",
                },
              },
            },
            tooltip: {
              y: {
                formatter: (v) => this.numberToLocaleFormat(v) + "₺",
              },
            },
          };

          this.analysisData = [
            {
              name: "Plan",
              data: response.data.data.map((v) => v.expected_amount),
            },
            {
              name: "Gerçekleşen",
              data: response.data.data.map((v) => v.actual_amount),
            },
          ];
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    load() {
      if (this.isLoading || !this.id) {
        return;
      }

      this.isLoading = true;
      this.formData = {};

      this.$api
        .get(`budget-items/${this.id}`)
        .then((response) => {
          this.formData = Object.assign({}, response.data.data);
          this.has_details = this.formData.has_details;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .then(() => {
          return this.$api.query(`budget-items/${this.id}/status`);
        })
        .then((response) => {
          this.totals = response.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      formData.budget_id = this.budgetId;

      if (this.has_details) {
        delete formData.amount;
        formData.has_details = true;
      } else {
        formData.has_details = false;
      }

      if (formData.id) {
        this.$api
          .put(`budget-items/${formData.id}`, formData)
          .then((res) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.hide();
            } else {
              this.formData = Object.assign({}, res.data.data);
              setTimeout(() => {
                if (this.$refs.monthList) {
                  this.$refs.monthList.loadList();
                }
              }, 100);
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`budget-items`, formData)
          .then((res) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.hide();
            } else {
              this.formData = Object.assign({}, res.data.data);
              setTimeout(() => {
                this.$refs.monthList.loadList();
              }, 100);
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    expenseChange() {
      this.load();
      if (this.$refs.monthList) {
        this.$refs.monthList.loadList();
      }
      if (this.$refs.detailExemptList) {
        this.$refs.detailExemptList.loadList();
      }
      this.$emit("updated");
    },
  },
};
</script>
<style lang="scss">
.custom-tabs.theme--light.v-tabs > .v-tabs-bar,
.custom-item-tabs.theme--light.v-tabs-items {
  background-color: transparent !important;
  padding: 0.5em;
}
</style>
