<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-if="budgetItemId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Dönemsel Tutarlar"
            icon=""
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            @click:edit="handleEditClick"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleDeleteClick"
            show-delete=""
          >
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <a href="#" @click.prevent="handleEditClick(item)">
            <i class="menu-icon mdi mdi-pencil"></i>
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-enable -->
      </v-data-table>
      <MonthsForm ref="monthsForm" @saved="loadList" />
      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDelete"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";
import MonthsForm from "./MonthsForm.vue";

export default {
  mixins: [hasDataTable, hasPermissions],
  props: {
    /**
     * Budget Item ID
     */
    budgetItemId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  components: {
    MonthsForm,
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Yıl",
          value: "year",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ay",
          value: "month",
          searchable: "number",
          align: "end",
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.budgetItemId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.budget_item_id = this.budgetItemId;
      params.order_by = "id";

      this.$api
        .query(`budget-item-months`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick(item) {
      if (this.isLoading) {
        return false;
      }
      let id = null;
      if (item && item.id) {
        id = item.id;
      } else {
        if (this.selectedItems.length !== 1) {
          return false;
        } else {
          id = this.selectedItems[0].id;
        }
      }

      this.$refs.monthsForm.show(id);
    },
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`budget-item-months/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.selectedItems = [];
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
      this.$refs.confirmDelete.hide();
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        "Dönemsel tutar kaydını silmek istediğinizden emin misiniz?"
      );
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    budgetItemId: {
      handler() {
        this.loadList();
      },
    },
  },
};
</script>
