<template>
  <v-card>
    <rs-action @click="calculateBudget"> Hesapla </rs-action>
    <v-card-text>
      <v-data-table
        v-if="budgetId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="BB Sonuç"
            icon=""
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            hide-edit
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.other_income_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.expense_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.exempt_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-enable -->
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  mixins: [hasDataTable],
  props: {
    /**
     * Budget ID
     */
    budgetId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "BB No",
          value: "house_name",
          searchable: "text",
          align: "center",
        },
        {
          text: "Diğer Gelir Tutar",
          value: "other_income_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gider Tutar",
          value: "expense_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Muafiyet Tutar",
          value: "exempt_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Aylık Net Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.budgetId) {
        return;
      }
      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.budget_id = this.budgetId;
      params.order_by = "house_name";

      this.$api
        .query(`budget-houses`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }
          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    openPeriodicForm() {
      this.$router.push({
        name: "definitions.periodical-assessments.create",
      });
    },
    calculateBudget() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`budgets/${this.budgetId}/calculate`, {})
        .then(() => {
          this.$toast.success(this.$t("saved"));
          setTimeout(() => {
            this.loadList();
          }, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    budgetId: {
      handler() {
        this.loadList();
      },
    },
  },
};
</script>
