<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="formData.name">
          <small>Bütçe</small><br />
          <span>{{ formData.name }}</span>
        </div>
        <span v-else>Bütçe Ekle</span>
      </h3>
      <v-col class="text-right p-0" v-if="formData.id">
        <rs-action @click="startBudget" v-if="!formData.is_active">
          Bütçe Aktifleştir
        </rs-action>
        <rs-action @click="stopBudget" v-else> Bütçeyi Durdur </rs-action>
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-form
        v-if="clusterId"
        ref="form"
        @submit.prevent="handleFormSubmit"
        :disabled="isLoading || formData.is_active"
      >
        <v-row sm="6" dense>
          <v-col sm="6">
            <rs-select
              label="Borçlandırma Türü"
              :rules="[rules.required]"
              v-model="formData.income_type_id"
              :items="incomeTypeList"
              :readonly="formData.is_active"
              :filled="formData.is_active"
            />
          </v-col>
          <v-col sm="6">
            <rs-text-field
              label="Adı"
              v-model="formData.name"
              :rules="[
                rules.required,
                rules.minLength(formData.name, 1),
                rules.maxLength(formData.name, 100),
              ]"
              :readonly="formData.is_active"
              :filled="formData.is_active"
            />
          </v-col>
          <v-col sm="6">
            <rs-datepicker
              label="Başlangıç Tarihi"
              v-model="formData.starts_on"
              :rules="formData.is_active ? [] : [rules.required]"
              :disabled="formData.is_active"
            />
          </v-col>
          <v-col sm="6">
            <rs-datepicker
              label="Bitiş Tarihi"
              v-model="formData.ends_on"
              :disabled="formData.is_active || !formData.starts_on"
              :rules="
                formData.is_active || !formData.starts_on
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.ends_on, formData.starts_on),
                    ]
              "
              :min="formData.starts_on"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="handleCancelClick"
          v-if="!formData.is_active"
          @submit.prevent="handleFormSubmit"
        />

        <v-divider class="my-10" v-if="id" />

        <v-row dense v-if="id && totals.month_count">
          <v-col cols="12" sm="4" lg="3">
            <rs-text-field
              label="Dönem Sayısı"
              filled
              readonly
              :value="
                totals.month_count + ' ay / ' + totals.days_count + ' gün'
              "
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Planlanan Gider"
              :value="totals.total_amount"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Aylık Gider Tutar"
              :value="totals.monthly_amount"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Gerçekleşen Tutar"
              :value="totals.actual_amount"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Gerçekleşen Aylık Tutar"
              :value="totals.monthly_actual_amount"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-money-field
              disabled
              label="Yıl Sonu Tahmini Tutar"
              :value="totals.expected_amount"
            />
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <rs-is-active v-model="formData.is_active" disabled />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider class="my-10 mx-5" />
    <v-card-text v-if="formData.id">
      <v-tabs v-model="selectedTab">
        <v-tab to="#detail" replace>Bütçe Kalemleri</v-tab>
        <v-tab to="#item-groups" replace>Kalem Grupları</v-tab>
        <v-tab to="#exempts-outcomes" replace>Kapsam Dışı Giderler</v-tab>
        <v-tab to="#exempts-houses" replace>Muaf Bağımsız Bölümler</v-tab>
        <v-tab to="#other-incomes" replace>Diğer Gelirler</v-tab>
        <v-tab to="#house-result" replace>BB Sonuç</v-tab>
        <v-tab to="#house-type-result" replace>BB Tip Sonuç</v-tab>
        <v-tab to="#analysis" replace>Plan-Gerçekleşen Analiz</v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="detail">
          <DetailList :budget-id="id" @saved="load()" />
        </v-tab-item>
        <v-tab-item value="item-groups">
          <ItemGroupList :budget-id="id" @saved="load()" />
        </v-tab-item>
        <v-tab-item value="exempts-outcomes">
          <ExemptList :budget-id="id" :is-house="false" />
        </v-tab-item>
        <v-tab-item value="exempts-houses">
          <ExemptList :budget-id="id" :is-house="true" />
        </v-tab-item>
        <v-tab-item value="other-incomes">
          <OtherIncomeList :budget-id="id" />
        </v-tab-item>
        <v-tab-item value="house-result">
          <HouseResultList :budget-id="id" />
        </v-tab-item>
        <v-tab-item value="house-type-result">
          <HouseTypeResultList :budget-id="id" />
        </v-tab-item>
        <v-tab-item value="analysis">
          <BudgetAnalysis :budget-id="id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";
import DetailList from "../components/detail/DetailList.vue";
import OtherIncomeList from "../components/other-income/OtherIncomeList.vue";
import ExemptList from "../components/exempt/ExemptList.vue";
import HouseResultList from "../components/house-result/HouseResultList.vue";
import HouseTypeResultList from "../components/house-type-result/HouseTypeResultList.vue";
import BudgetAnalysis from "../components/analysis/BudgetAnalysis.vue";
import ItemGroupList from "../components/item-groups/ItemGroupList.vue";

export default {
  mixins: [hasForm],
  components: {
    BudgetAnalysis,
    DetailList,
    OtherIncomeList,
    ExemptList,
    HouseResultList,
    HouseTypeResultList,
    ItemGroupList,
  },
  props: {
    /**
     * Budget ID if editing
     */
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "incomeTypeList"]),
  },
  watch: {
    id() {
      this.load();
    },
    clusterId: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.clusterId) {
            this.formData.cluster_id = this.clusterId;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
  data() {
    return {
      selectedTab: "info",
      formData: {
        name: null,
        starts_on: null,
        ends_on: null,
        income_type_id: null,
      },
      totals: {},
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      this.$api
        .query(`budgets/${this.id}`)
        .then((response) => {
          this.formData = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .then(() => {
          return this.$api.query(`budgets/${this.id}/status`);
        })
        .then((response) => {
          this.totals = response.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$api
          .put(`budgets/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`budgets`, formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "budget.definition.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    startBudget() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`budgets/${this.id}/start`, {})
        .then(() => {
          this.$toast.success(this.$t("saved"));
          this.load();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    stopBudget() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`budgets/${this.id}/stop`, {})
        .then(() => {
          this.$toast.success(this.$t("saved"));
          this.load();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
