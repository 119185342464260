<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-if="budgetItemId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Muaf Bağımsız Bölümler"
            icon=""
            @click:add="handleAddClick"
            :add-route="'#'"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            hide-edit
            :search.sync="search.query"
            v-bind="titleBarAttrs"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleDeleteClick"
            show-delete=""
          >
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-enable -->
      </v-data-table>
      <DetailExemptForm ref="detailExemptForm" @saved="loadList" />
      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDelete"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";
import DetailExemptForm from "./DetailExemptForm.vue";

export default {
  mixins: [hasDataTable],
  props: {
    /**
     * Budget Item ID
     */
    budgetItemId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  components: {
    DetailExemptForm,
  },
  computed: {
    ...mapGetters(["expenseTypeList"]),
  },
  data() {
    return {
      headers: [
        {
          text: "BB No",
          value: "house_name",
          searchable: "text",
          align: "center",
        },
        {
          text: "Muafiyet Oranı (%)",
          value: "exemption_ratio",
          searchable: "number",
          align: "end",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.budgetItemId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.budget_item_id = this.budgetItemId;
      params.order_by = "house_name";

      this.$api
        .query(`budget-item-exempts`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.detailExemptForm.show(this.budgetItemId);
    },
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`budget-item-exempts/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.selectedItems = [];
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
      this.$refs.confirmDelete.hide();
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        "Bütçe Kodu Muafiyet BB kaydını silmek istediğinize emin misiniz?"
      );
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    budgetItemId: {
      handler() {
        this.loadList();
      },
    },
  },
};
</script>
