<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bütçe Kalem Grubu</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="12">
            <rs-text-field
              label="Başlık"
              :rules="[rules.required, rules.maxLength(formData.name, 250)]"
              v-model="formData.name"
              :disabled="isLoading"
            />
          </v-col>
          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              :rules="[rules.maxLength(formData.description, 1000)]"
              v-model="formData.description"
              :disabled="isLoading"
            />
          </v-col>
          <v-col sm="12">
            <rs-select
              label="Bütçe Kalemleri"
              :items="itemList"
              item-text="expense_type_name"
              item-value="id"
              multiple
              v-model="formData.budget_item_ids"
              :disabled="isLoading"
              :custom-item="true"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{
                    data.item.expense_type_name
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    Dağıtım: {{ data.item.distribution_type_name }}
                    <template v-if="data.item.child_expense_types">
                      | Detay: {{ data.item.child_expense_types }}
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </rs-select>
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          hide-submit
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { hasDataTable, hasForm } from "@/view/mixins";

export default {
  mixins: [hasDataTable, hasForm],
  watch: {
    "formData.budget_id": function () {
      this.loadItemList();
    },
  },
  data() {
    return {
      id: null,
      itemList: [],
      formData: {
        name: null,
        description: null,
        budget_id: null,
        budget_item_ids: [],
      },
    };
  },
  methods: {
    show(id, budgetId) {
      if (id) {
        this.id = id;
        this.load();
      } else {
        this.formData = {
          name: null,
          description: null,
          budget_item_ids: null,
          budget_id: null,
        };
      }
      if (budgetId) {
        this.formData.budget_id = budgetId;
      } else {
        return false;
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    load() {
      if (this.isLoading || !this.id) {
        return;
      }

      this.isLoading = true;

      this.$api
        .get(`budget-item-groups/${this.id}`)
        .then((response) => {
          this.formData = Object.assign({}, response.data.data);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadItemList() {
      if (!this.formData.budget_id) {
        return;
      }

      this.itemList = [];

      this.isLoading = true;
      const params = this.buildSearchParams({
        budget_id: this.formData.budget_id,
        order_by: "expense_type_name",
      });

      this.$api
        .query(`budget-items`, { params })
        .then((response) => {
          this.itemList = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      if (this.id) {
        this.$api
          .put(`budget-item-groups/${this.id}`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`budget-item-groups`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
