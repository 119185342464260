<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      {{ isHouse ? "Bütçe Muafiyet BB" : "Bütçe Kapsam Dışı Gider" }}</template
    >
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense v-if="isHouse">
          <v-col sm="12">
            <rs-text-field
              type="number"
              min="1"
              step="1"
              max="100"
              label="Muafiyet Oranı"
              v-model="formData.exemption_ratio"
              :rules="[rules.required]"
              :readonly="isLoading"
              :filled="isLoading"
              prefix="%"
            />
          </v-col>
          <v-col sm="12">
            <BBList @update="handleSelectionUpdate" />
          </v-col>
        </v-row>
        <v-row cols="12" dense v-else>
          <v-col sm="12">
            <rs-select-expense-type
              v-model="formData.expense_type_id"
              :label="$t('labels.expense_type')"
              :rules="[rules.required]"
              :readonly="isLoading"
              :filled="isLoading"
            />
          </v-col>
          <v-col sm="12">
            <rs-select
              :items="childExpenseTypeList"
              label="Gider Tipi"
              v-model="formData.child_expense_type_id"
              :readonly="isLoading"
              :filled="isLoading"
              :disabled="!formData.expense_type_id"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          hide-submit
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";
import { mapGetters } from "vuex";
import ClusterService from "@/core/services/cluster.service";
import BBList from "./BBList";

export default {
  mixins: [hasForm],
  data() {
    return {
      isHouse: false,
      formData: {},
      selectedIds: [],
    };
  },
  components: {
    BBList,
  },
  computed: {
    ...mapGetters(["clusterId"]),
    childExpenseTypeList() {
      if (!this.formData.expense_type_id) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(
        this.formData.expense_type_id
      );
    },
  },
  methods: {
    show(budgetId, isHouse) {
      this.formData = {};
      if (isHouse) {
        this.formData.exemption_ratio = 100;
        this.isHouse = true;
      } else {
        this.isHouse = false;
      }

      if (budgetId) {
        this.formData.budget_id = budgetId;
      } else {
        return false;
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.isHouse) {
        formData.type = "house";
        formData.house_id = this.selectedIds;
      } else {
        formData.type = "expense_type";
      }

      this.$api
        .post(`budget-exempts`, formData)
        .then(() => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleSelectionUpdate(newItems) {
      this.selectedIds = newItems.map((v) => v.id);
    },
  },
};
</script>
